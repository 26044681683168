<template>
  <product title="可穿戴动态心电记录仪"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1899"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/88GjKh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'probe',
  components: {
    Product
  },
  data () {
    return {
      slogan: '非处方心电图 | 一键记录 | 实时监测 | 胸部电极 | 手环设计',
      colors: [
        '黑色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/probe/swiper/1.png',
          CDN_URL + '/images/product/probe/swiper/2.png',
          CDN_URL + '/images/product/probe/swiper/3.png',
          CDN_URL + '/images/product/probe/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/01-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/02-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/03-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/04-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/05-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/06-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/07-1.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/08-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/09-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/10-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/11-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/12-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/13-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/14-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/probe/15-1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
s
